import React, { useEffect, useRef, useState, useContext } from "react";
import { CompanyContext } from '../../global_setting/Store';
import config from '../../../src/config.json'
import GoogleMapReact from "google-map-react";

const styles = {
  width: "100%",
  minHeight: "380px",
  height: "380px",
};

const MapBoxComponent = () => {
  const [companyDetails] = useContext(CompanyContext);
  const [mapDetails, setMapDetails] = useState();
  const [latitude, setLatitude] = useState("");
  const [longitude, setLongitude] = useState("");

  var company_id_val = companyDetails && companyDetails.company_id;
  useEffect(() => {
    (async function () {
      try {
        if (company_id_val) {
          var company_id = companyDetails.company_id
          var template_id = companyDetails.template_id
          const mapData = await fetch(
            config.apiEndpoint + '/wb-frontview/get-frontview-map-data/' + company_id + '/' + template_id
          );
          const result = await mapData.json();
          setMapDetails({ "hotel_details": result && result.hotel_details });

        }
      } catch (e) {
        console.error(e);
      }
    })();
  }, [company_id_val])


  useEffect(() => {
    if (mapDetails && mapDetails.hotel_details) {
      let lat = parseFloat(mapDetails.hotel_details.latitude);
      let lng = parseFloat(mapDetails.hotel_details.longitude);

      setLatitude(lat);
      setLongitude(lng);
    }
  }, [mapDetails]);

  // google map marker component

  const renderMarkers = (map, maps) => {
    let marker = new maps.Marker({
      position: { lat: latitude, lng: longitude },
      map,
      title: mapDetails?.hotel_details?.hotel_address,
    });
    return marker;
  };

  return (
    <div className="container-fluid google-map-wrap">
      <div className="row">
        <div className="google__map__wrapper" style={styles}>
          {mapDetails && latitude && longitude &&
            <GoogleMapReact
              bootstrapURLKeys={{
                key: "AIzaSyBceMBt5GputUmynEHqVn0L8rRByrIFU5M",
              }}
              options={{
                mapTypeId: "roadmap",
                fullscreenControl: false,
              }}
              center={{
                lat: parseFloat(latitude),
                lng: parseFloat(longitude),
              }}
              zoom={16}
              onGoogleApiLoaded={({ map, maps }) => renderMarkers(map, maps)}
            >
            </GoogleMapReact>
          }
        </div>
      </div>
    </div>

  )


};

export default MapBoxComponent;