import React, { useState, useContext, useEffect } from "react";
import { CompanyContext } from "../../../global_setting/Store";
import config from "../../../../src/config.json";
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";

const TestimonialSection = () => {
  const [companyDetails] = useContext(CompanyContext);
  const [testimonialSectionData, setTestimonialSectionData] = useState();
  let company_id_val = companyDetails && companyDetails.company_id;

  useEffect(() => {
    (async function () {
      try {
        if (company_id_val) {
          let company_id = companyDetails.company_id;
          let template_id = companyDetails.template_id;
          const testimonialSection = await fetch(
            config.apiEndpoint +
              "/wb-frontview/get-frontview-testimonial-section-data/" +
              company_id +
              "/" +
              template_id
          );
          const result = await testimonialSection.json();

          setTestimonialSectionData({
            testimonial_data: result && result.hotel_testimonials,
            section_details: result && result.section_details,
          });
        }
      } catch (e) {
        console.error(e);
      }
    })();
  }, [company_id_val]);

  let websitefont = "";
  let section_details =
    testimonialSectionData && testimonialSectionData.section_details;

  console.log(testimonialSectionData, "testimonialSectionData");

  return (
    <div>
      <section
        className="choose_resort_area awe-parallax"
        style={
          section_details && {
            backgroundImage:
              "url(" +
              config.wbImagesEndpoint +
              "/" +
              section_details.background_image +
              ")",
          }
        }
      >
        <div className="container">
          {section_details && (
            <div className="center_title">
              <h2
                className="heading text-center testimonial-heading"
                style={{ fontFamily: websitefont }}
              >
                {section_details.section_title}
              </h2>
              <div className="text">
                <p
                  dangerouslySetInnerHTML={{
                    __html: section_details.section_description,
                  }}
                ></p>
              </div>
            </div>
          )}
          {testimonialSectionData &&
            testimonialSectionData.testimonial_data &&
            testimonialSectionData.testimonial_data.length > 0 && (
              <div>
                <OwlCarousel
                  className="owl-theme"
                  items={1}
                  autoplay={true}
                  autoplaySpeed={950}
                  loop
                  nav
                  play={(5000, 5000)}
                >
                  {testimonialSectionData.testimonial_data.map(function (
                    item,
                    i
                  ) {
                    return (
                      <div className="item" key={i}>
                        <div className="carousel slide" id="quote-carousel">
                          <div className="carousel-inner text-center">
                            <div className="item active">
                              <div className="row">
                                <div className="col-sm-8 col-sm-offset-2 testomony__main__wrapper">
                                  <div className="testimony__image__wrapper">
                                    <img
                                      src={
                                        config.wbImagesEndpoint +
                                        "/" +
                                        item?.testimonial_image
                                      }
                                      alt={
                                        item?.testimonial_image_alt ||
                                        "Testimony_img"
                                      }
                                      className="testimonial__image"
                                    />
                                  </div>
                                  <p
                                    className="testimony-text"
                                    dangerouslySetInnerHTML={{
                                      __html: item.testimonial_description,
                                    }}
                                  ></p>
                                  <small className="author-text">
                                    {item.testimonial_title}
                                  </small>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    );
                  })}
                </OwlCarousel>
              </div>
            )}
        </div>
      </section>
    </div>
  );
};

export default TestimonialSection;
